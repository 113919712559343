@import '../../../Styles/settings';

.priceInfoWrapper {
  flex: 0 0 100%;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.priceWrapper {
  flex: 0 0 100%;
  padding: 10px 0 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  @include HelveticaMedium;
  line-height: inherit;
  margin: 0;
  margin-left: auto;
  margin-top: 8px;
  font-size: 14px;
}

.qty {
  flex: 0 0 100%;
  max-width: 100%;
}

@include media(600px) {
  .unit {
    white-space: nowrap;
    width: calc(50% + 47px);
  }
}

@include media(700px) {
  .priceInfoWrapper {
    flex: 0 0 198px;
    max-width: 198px;
    justify-content: flex-end;
  }
  .priceWrapper {
    flex: 0 0 100%;
    max-width: none;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    flex-wrap: nowrap;
    margin-top: 0;
  }

  .price {
    padding-right: 0;
    padding-left: 10px;
    white-space: nowrap;
  }
}

@media #{$md-up} {
  .price {
    font-size: 16px;
  }

  .unit,
  .qty {
    font-size: 16px;
  }
}
