@import '../../../Styles/settings';

.root {
  padding-top: 20px;
  overflow: hidden;
}

.h3 {
  font-weight: 300;
  text-align: center;
}

.shareContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -15px;
}

.shareItem {
  flex: 0 0 70px;
  max-width: 70px;
  padding: 0 15px;
}
