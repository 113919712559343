@import '../../../Styles/settings';

.heroSection {
    background-color: $bona-dark-blue;
  }

  .textContainer {
    padding: 32px 16px;
  }

  .h1 {
    @include HelveticaMedium;
    color: $white;
    margin-bottom: 24px;
    line-height: 40px;
    // text-align: center;
  }

  .infoText {
    color: $white;
  }

  .linkContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  @media #{$md-up} {
    .heroSection {
      margin-bottom: 64px;
    }
  
    .h1 {
      line-height: 64px;
      margin-bottom: 32px;
    }

    .infoText {
        margin-bottom: 32px;
        max-width: 50%;
    }
    
    .textContainer {
      max-width: 1158px;
      margin: auto;
      padding: 65px 15px 55px 15px;
    }
  
    .linkContainer {
      flex-direction: row;
      column-gap: 24px;
      max-height: 40px;
    }
  }