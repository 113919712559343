@import '../../../Styles/settings';

.infoContainer {
  display: flex;
  flex-direction: column;
  color: $darkest-gray;
}

.infoTitle {
  @include HelveticaMedium;
  margin-top: 12px;
  color: $black;
}

@media #{$md-up} {
  .infoText {
    margin-bottom: 32px;
    max-width: 50%;
  }

  .cardTitle {
    @include HelveticaMedium;
    font-size: 24px;
    color: $black;
  }
}
