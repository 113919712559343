@import '../../../Styles/settings';

.root {
  display: flex;
  grid-column-end: span 12;
  width: 100%;
  position: relative;
  &:not(:last-of-type) {
    border-bottom: 1px solid $light-gray;
  } 
}

.wrapper {
  display: flex;
  flex-basis: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  align-items: center;
  background: white;
  padding: 10px 0;
  margin-top: 12px;
  &.noBgListMode {
    background: transparent;
  }
}

.root {
  &.noBgListMode {
    z-index: map-get($zIndexes, 'productCard');
  }
}

@media #{$md-up} {
  .root {  
    &:not(:last-of-type) {
      border-bottom: unset;
    } 
  }

  .wrapper {
    padding: 20px 0px;
    margin-top: 0;
  }
}
